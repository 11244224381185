import React from "react";
import { Container, Row, Col } from "reactstrap";


class Footer extends React.Component {
    render () {
        return (
            <div>
                <p style={{paddingTop:"200px"}}></p>
            </div>
        )
    }
    }



export default Footer; 